import { useQuery } from '@tanstack/react-query'
import { getEvent } from '../api'

export const useEvent = ({ id }) => {
  const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['event', id],
    queryFn: () => getEvent(id),
    enabled: !!id,
  })

  if (data?.user) localStorage.setItem('token', data.user)

  const event = data?.value ?? []

  return { event, isFetching, isLoading, isError, error, refetch }
}
