import React, { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ClockIcon,
  MapIcon,
  UserIcon,
  TagIcon,
} from '@heroicons/react/24/outline'
import { SwitchToggle, Alert } from '../components'
import { patchRegistrant } from '../api'

export default function AttendeeCard({
  attendee,
  format,
  isFetching,
  refetch,
}) {
  const queryClient = useQueryClient()
  const [alert, setAlert] = useState(null)

  const mutation = useMutation({
    mutationFn: () =>
      patchRegistrant(attendee.id, { attended: !attendee.attended }),
    onSuccess: () => {
      console.log('Mutation successful, refetching data')
      setAlert({ message: 'Attendance updated!', type: 'success' })
      queryClient.invalidateQueries({ queryKey: ['event'] })
    },
    onError: (error) => {
      console.log('Mutation error')
      setAlert({
        message: `An error occurred: ${error.message}`,
        type: 'error',
      })
    },
  })

  const handleToggle = () => {
    // if (!attendee.attended) {
    mutation.mutate()
    //}
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <>
      <div className="w-full bg-white shadow p-4 min-h-40 rounded flex flex-col">
        <div className="w-full min-h-14 flex items-center">
          <UserIcon className="h-10 w-10 text-[#B3A369]" />
          <h1 className="text-gray-800 text-left ml-2 min-h-14 text-lg font-bold flex items-center">
            {attendee.registrant_name}
          </h1>
        </div>
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <TagIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {attendee.status}
          </p>
        </div>
        <div className="h-7 w-full mt-2 px-1 flex flex-col xl:flex-row justify-start xl:items-center xl:justify-between">
          <div className="flex items-center">
            <ClockIcon className="h-4 w-4 text-[#003057]" />
            <p className="px-2 text-sm text-left flex items-center">
              {attendee.registration_type}
            </p>
          </div>
        </div>
        <div className="h-full w-full max-w-xs flex flex-col py-4">
          {mutation.isPending ? (
            <p>Updating attendance...</p>
          ) : isFetching ? (
            <p>Updating...</p>
          ) : (
            <>
              <label className="relative inline-flex items-center cursor-pointer">
                <SwitchToggle
                  handleToggle={handleToggle}
                  attended={attendee.attended}
                />
                <span
                  className={classNames(
                    'ml-3 text-sm font-medium',
                    attendee.attended ? 'text-gray-900' : 'text-gray-400'
                  )}>
                  Attended
                </span>
              </label>
            </>
          )}
        </div>
      </div>
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  )
}
