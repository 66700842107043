import { useEvent } from '../hooks'
import {
  AttendeeCard,
  NewRegistrationModal,
  EditEventModal,
  EventCard,
} from '../components'
import { SkeletonAttendee } from '../components/Skeletons'
import React, { useState } from 'react'
import {
  PlusIcon,
  PencilIcon,
  CalendarDaysIcon,
  ClockIcon,
  IdentificationIcon,
} from '@heroicons/react/24/outline'

export default function EventModal({
  closeModal,
  isClosing,
  modalEvent,
  format,
}) {
  const { event, isFetching, isLoading, isError, error, refetch } = useEvent({
    id: modalEvent.id,
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [isNewRegistrationOpen, setIsNewRegistrationOpen] = useState(false)
  const [isEditEventOpen, setIsEditEventOpen] = useState(false)

  const filteredAttendees = event.filter((attendee) =>
    attendee.registrant_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <div className="fixed inset-0 flex items-end justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-80"
        onClick={closeModal}></div>
      <div
        className={`bg-[#f3f4f2] p-8 rounded-t-xl shadow-lg w-screen h-[calc(100vh-50px)] z-[1000] ${
          isClosing ? 'animate-slide-down' : 'animate-slide-up'
        } overflow-y-auto`}>
        <div className="w-full min-h-36 rounded flex flex-col">
          <div className="w-full min-h-14 flex">
            <div className="h-14 w-14 sm:flex items-center hidden sm:visible">
              <CalendarDaysIcon className="h-10 w-10 text-[#B3A369]" />
            </div>
            <div className="min-h-14 w-full">
              <h1 className="text-gray-800 text-left ml-2 min-h-14 text-lg sm:text-2xl font-bold flex items-center">
                {modalEvent.title}
              </h1>
            </div>
          </div>
          <div className="min-h-7 w-full mt-2 px-1 flex items-center">
            <ClockIcon className="h-4 w-4 shrink-0 text-[#003057]" />
            <p className="px-2 text-sm text-left flex items-center">
              {format(modalEvent.startDate, 'MMMM d, yyyy')} to{' '}
              {format(modalEvent.endDate, 'MMMM d, yyyy')}
            </p>
          </div>
          <div className="h-7 w-full mt-2 px-1 flex flex-col xl:flex-row justify-start xl:items-center xl:justify-between">
            <div className="flex items-center">
              <IdentificationIcon className="h-4 w-4 text-[#003057]" />
              <p className="px-2 text-sm text-left flex items-center">
                {modalEvent.lookup_id}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center items-start gap-2">
          <button
            className="bg-[#D6DBD4] px-4 py-2 rounded"
            onClick={closeModal}>
            Close
          </button>
          {/* <button
            className="bg-[#008C95] text-white px-4 py-2 rounded flex items-center gap-2"
            onClick={() => setIsEditEventOpen(true)}
          >
            <PencilIcon className="w-4 h-4" /> Edit Event
          </button> */}
          <button
            className="bg-[#B3A369] text-white px-4 py-2 rounded flex items-center gap-2"
            onClick={() => setIsNewRegistrationOpen(true)}>
            <PlusIcon className="w-4 h-4" /> New Registration
          </button>
        </div>

        <input
          type="text"
          placeholder="Search attendees"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="mb-4 p-2 border rounded w-full mt-8"
        />

        {isLoading ? (
          <SkeletonAttendee />
        ) : isError ? (
          <p>Error: {error.message}</p>
        ) : (
          <>
            <p className="text-gray-500">{filteredAttendees.length} total</p>
            {filteredAttendees.map((attendee) => (
              <div
                key={attendee.id}
                className="mt-4">
                <AttendeeCard
                  attendee={attendee}
                  format={format}
                  isFetching={isFetching}
                  refetch={refetch}
                />
              </div>
            ))}
          </>
        )}
      </div>

      {isNewRegistrationOpen && (
        <NewRegistrationModal
          modalEvent={modalEvent}
          closeModal={() => setIsNewRegistrationOpen(false)}
        />
      )}

      {isEditEventOpen && (
        <EditEventModal
          closeModal={() => setIsEditEventOpen(false)}
          event={modalEvent}
        />
      )}
    </div>
  )
}
