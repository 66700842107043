export default function SkeletonAttendee() {
  return (
    <div className="mt-4 animate-pulse">
      <ul>
        {Array.from({ length: 5 }).map((_, index) => (
          <li
            key={index}
            className="mb-2">
            <div className="w-full text-left p-2 bg-gray-300 rounded h-14"></div>
          </li>
        ))}
      </ul>
    </div>
  )
}
