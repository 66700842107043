import {
  FlagIcon,
  MapIcon,
  UserIcon,
  TagIcon,
  AcademicCapIcon,
  IdentificationIcon,
  MapPinIcon,
  GlobeAmericasIcon,
  EnvelopeIcon,
} from '@heroicons/react/24/outline'

export default function ConstituentCard({
  constituent,
  selectedConstituent,
  setSelectedConstituent,
  setIsSidebarOpen,
}) {
  const handleClick = () => {
    setSelectedConstituent(constituent)
    setIsSidebarOpen(false)
  }
  return (
    <button
      onClick={handleClick}
      className={`w-full bg-white shadow p-4 min-h-40 rounded flex flex-col ${
        constituent === selectedConstituent ? 'border border-[#003057]' : ''
      }`}>
      {constituent.sort_constituent_name && (
        <div className="w-full min-h-14 flex items-center">
          <UserIcon className="h-10 w-10 text-[#B3A369]" />
          <h1 className="text-gray-800 text-left ml-2 min-h-14 text-lg font-bold flex items-center">
            {constituent.sort_constituent_name}
          </h1>
        </div>
      )}

      {constituent.lookup_id && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <IdentificationIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.lookup_id}
          </p>
        </div>
      )}

      {constituent.classof !== 0 && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <AcademicCapIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.classof}
          </p>
        </div>
      )}

      {constituent.address && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <MapPinIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.address}
          </p>
        </div>
      )}

      {constituent.city && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <MapIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.city}
          </p>
        </div>
      )}

      {constituent.state && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <FlagIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.state}
          </p>
        </div>
      )}

      {constituent.country_id && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <GlobeAmericasIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.country_id}
          </p>
        </div>
      )}

      {constituent.email_address && (
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <EnvelopeIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="px-2 text-sm text-left flex items-center">
            {constituent.email_address}
          </p>
        </div>
      )}
    </button>
  )
}
