import React from 'react'
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSameMonth,
} from 'date-fns'

function SkeletonCalendar() {
  const today = new Date()
  const currentMonth = new Date()
  const startMonth = startOfMonth(currentMonth)
  const endMonth = endOfMonth(currentMonth)
  const startDate = startOfWeek(startMonth)
  const endDate = endOfWeek(endMonth)
  const days = eachDayOfInterval({ start: startDate, end: endDate })

  return (
    <div className="max-w-7xl mx-auto">
      <div className="flex h-[692px] animate-pulse">
        <div className="w-full lg:w-1/4 lg:p-4 lg:border-r overflow-y-scroll no-scrollbar h-full">
          <h2 className="text-xl font-bold mb-4 bg-gray-300 rounded h-8 w-3/4">
            {' '}
          </h2>
          <ul>
            {Array.from({ length: 5 }).map((_, index) => (
              <li
                key={index}
                className="mb-2">
                <div className="w-full text-left p-2 bg-gray-300 rounded h-40"></div>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-3/4 p-4 hidden lg:visible lg:block">
          <div className="flex justify-between items-center mb-4">
            <button className="bg-gray-300 text-white px-4 py-2 rounded h-10 w-24"></button>
            <h2 className="text-xl font-bold bg-gray-300 rounded h-8 w-1/2">
              {' '}
            </h2>
            <button className="bg-gray-300 text-white px-4 py-2 rounded h-10 w-24"></button>
          </div>
          <div className="calendar grid grid-cols-7 gap-1">
            {days.map((day, index) => (
              <div
                key={index}
                className={`p-2 border ${
                  isSameMonth(day, today) ? '' : 'bg-gray-200'
                }`}
                style={{ height: '100px' }}>
                <div className="text-right bg-gray-300 rounded h-6 w-6 ml-auto"></div>
                <div className="mt-1 p-1 bg-gray-300 rounded h-6"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonCalendar
