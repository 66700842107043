export default function EditEventModal({ closeModal, event }) {

  return (
    <div className="fixed inset-0 flex items-end justify-center z-[1500]">
      <div
        className="fixed inset-0 bg-black opacity-0"
        onClick={closeModal}
      ></div>
      <div className="bg-white p-4 rounded-t-xl shadow-lg w-screen h-[calc(100vh-50px)] z-[1000] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Edit Event</h2>
        <button
          className="bg-black text-white px-4 py-2 rounded"
          onClick={closeModal}
        >
          Close
        </button>
        {/* Add your Edit Event form here */}
      </div>
    </div>
  )
}
