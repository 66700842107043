import { Calendar, SkeletonCalendar, LoadingIndicator } from '../components'
import { useEvents } from '../hooks'
import { Error, SearchEvents } from '../components'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/solid'

const Home = () => {
  const { logout } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useState({})
  const [filteredEvents, setFilteredEvents] = useState([])
  const { events, isLoading, isError, error } = useEvents({
    searchParams: searchParams,
  })

  // const handleSearch = (params, filtered) => {
  //   setSearchParams(params)
  //   setFilteredEvents(filtered)
  // }

  const handleSearch = (params) => {
    setSearchParams(params)
  }

  useEffect(() => {
    setFilteredEvents(events)
  }, [])

  return (
    <>
      <div className="w-full h-[480px] lg:h-[207px] top-0 z-20 bg-gradient-to-l from-[#003057] to-[#003057] flex fixed justify-end"></div>
      <div className="mx-auto relative z-20 py-8">
        <div className="max-w-7xl w-full h-14 flex items-center justify-between px-6 lg:px-8 mx-auto">
          <img
            src="https://www.gtf.gatech.edu/wp-content/uploads/2022/06/georgiatechfoundation-logo-white-alt-1.png"
            alt="Georgia Tech Foundation"
            className="h-14 w-auto"
          />
          <button
            onClick={logout}
            className="text-white flex items-center gap-2">
            Logout <ArrowRightEndOnRectangleIcon className="h-6 w-6" />
          </button>
        </div>

        <SearchEvents
          setFilteredEvents={setFilteredEvents}
          handleSearch={handleSearch}
          events={events}
        />

        <div className="max-w-7xl mx-auto px-4">
          {isLoading ? (
            <SkeletonCalendar />
          ) : isError ? (
            <Error error={error} />
          ) : (
            <Calendar events={filteredEvents ?? events} />
          )}
        </div>
      </div>
    </>
  )
}

export default Home
