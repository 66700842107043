import axios from 'axios'

const API_SERVER = 'https://gtfeventsbe.gtf.gatech.edu'

const instance = axios.create({
  baseURL: API_SERVER,
})

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
