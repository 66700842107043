import {
  ClockIcon,
  IdentificationIcon,
  CalendarDaysIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

export default function EventCard({ event, handleEventClick, format }) {
  return (
    <li key={event.id}>
      <button
        onClick={() => handleEventClick(event)}
        className="w-full bg-white shadow p-4 min-h-40 rounded flex flex-col">
        <div className="w-full min-h-14 flex">
          <div className="h-14 w-14 sm:flex items-center hidden sm:visible">
            <CalendarDaysIcon className="h-10 w-10 text-[#B3A369]" />
          </div>
          <div className="min-h-14 w-full">
            <h1 className="text-gray-800 text-left ml-2 min-h-14 sm:text-lg font-bold flex items-center">
              {event.title}
            </h1>
          </div>
        </div>
        <div className="min-h-7 w-full mt-2 px-1 flex items-center">
          <ClockIcon className="h-4 w-4 shrink-0 text-[#003057]" />
          <p className="text-xs px-2 sm:text-sm text-left flex items-center">
            {format(event.startDate, 'MMMM d, yyyy')} to{' '}
            {format(event.endDate, 'MMMM d, yyyy')}
          </p>
        </div>
        <div className="h-7 w-full mt-2 px-1 flex items-center justify-between">
          <div className="flex items-center">
            <IdentificationIcon className="h-4 w-4 text-[#003057]" />
            <p className="text-xs px-2 sm:text-sm text-left flex items-center">
              {event.lookup_id}
            </p>
          </div>
          <p className="text-sm mt-1 xl:mt-0 font-bold flex justify-end items-center">
            See more <ChevronRightIcon className="h-4 w-4 text-[#B3A369]" />
          </p>
        </div>
      </button>
    </li>
  )
}
