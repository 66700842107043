export const getUniqueArray = (arr) => {
  const uniqueIds = new Set()
  return arr.filter((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id)
      return true
    }
    return false
  })
}

export const getQueryString = (params) => {
  const query = new URLSearchParams()
  for (const key in params) {
    if (params[key]) {
      query.append(key, params[key])
    }
  }
  return query
}
