import { useNavigate } from 'react-router-dom'

export default function Error({ error }) {
  const navigate = useNavigate()

  return (
    <section className="bg-[#f3f4f2]">
      <div className="container flex items-center h-96 px-6 py-12 mx-auto">
        <div className="flex flex-col items-center max-w-sm mx-auto text-center">
          <p className="p-3 text-sm font-medium text-white rounded-full bg-[#008C95]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
              />
            </svg>
          </p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
            {error.message}
          </h1>
          <p className="mt-4 text-gray-500">
            It seems we have encountered an error.{' '}
            {error.response.status === 401 && 'Your access has expired.'}
          </p>

          <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
            {error.response.status === 401 && (
              <button
                onClick={() => (window.location.href = '/login')}
                className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-[#B3A369] rounded shrink-0 sm:w-auto hover:bg-[#B3A369] dark:hover:bg-[#B3A369] dark:bg-[#B3A369]">
                Login again
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
