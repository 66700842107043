import { useState } from 'react'
import { MagnifyingGlassIcon, FunnelIcon } from '@heroicons/react/24/solid'
import { EventCard } from '../components'

export default function SearchEvents({
  setFilteredEvents,
  handleSearch,
  events,
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchType, setSearchType] = useState('name')
  const [searchParams, setSearchParams] = useState({
    name: '',
    lookup_id: '',
  })

  const handleSearchChange = (e) => {
    const { name, value } = e.target
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSearch(searchParams)
    setIsSidebarOpen(false)
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const filteredEvents = events?.filter((event) =>
    event[searchType]?.toLowerCase()?.includes(searchQuery.toLowerCase())
  )

  const handleMainSearchChange = (e) => {
    const { value } = e.target
    setSearchQuery(value)

    if (searchType === 'name') {
      const filtered = events.filter((event) =>
        event.title.toLowerCase().includes(value.toLowerCase())
      )
      setFilteredEvents(filtered)
    }

    if (searchType === 'lookup_id') {
      const filtered = events.filter((event) =>
        event.lookup_id.toLowerCase().includes(value.toLowerCase())
      )
      setFilteredEvents(filtered)
    }
  }

  return (
    <>
      <div className="flex items-center justify-center mb-4 sticky top-0 z-50 bg-gradient-to-l from-[#003057] to-[#003057] mt-4 lg:h-[87px] py-4">
        <div className="min-h-10 gap-2 w-full max-w-7xl flex flex-col sm:flex-row px-4 lg:px-8">
          <input
            name="search"
            placeholder={`Search by ${searchType}`}
            value={searchQuery}
            onChange={handleMainSearchChange}
            className="w-full max-w-7xl h-10 p-2 border border-gray-200 rounded border-transparent focus:border-transparent focus:ring-0 focus:outline-none"
            type="text"
          />
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="h-10 p-2 rounded">
            <option value="name">Name</option>
            <option value="lookup_id">Lookup ID</option>
          </select>

          <button
            onClick={toggleSidebar}
            className="h-10 bg-[#B3A369] w-10 text-white flex items-center justify-center rounded">
            <FunnelIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      {isSidebarOpen && (
        <div
          onClick={() => setIsSidebarOpen(false)}
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-[1000]">
          <div
            className="bg-[#f3f4f2] w-full sm:w-2/4 lg:w-2/5 h-full flex flex-col"
            onClick={(e) => e.stopPropagation()}>
            <div className="flex-1 overflow-y-auto">
              <div className="p-4">
                <h2 className="text-2xl mb-4">Find Event</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block">Name</label>
                    <input
                      name="name"
                      placeholder="Name"
                      value={searchParams.name}
                      onChange={handleSearchChange}
                      className="w-full p-2 rounded shadow"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block">Lookup Id</label>
                    <input
                      name="lookup_id"
                      placeholder="Lookup Id"
                      value={searchParams.lookup_id}
                      onChange={handleSearchChange}
                      className="w-full p-2 rounded shadow"
                      type="text"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#008C95] text-white p-2 rounded">
                    Find
                  </button>
                  <button
                    type="button"
                    onClick={toggleSidebar}
                    className="w-full bg-[#D6DBD4] p-2 rounded mt-2">
                    Close
                  </button>
                </form>
              </div>
              {/* {filteredEvents?.length > 0 && (
                <div className="sticky top-0 z-10 bg-[#f3f4f2] p-4 shadow">
                  <input
                    type="text"
                    placeholder="Search constituents"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    className="mb-4 p-2 rounded w-full shadow"
                  />
                  <select
                    value={searchType}
                    onChange={handleSearchTypeChange}
                    className="mb-4 p-2 rounded w-full shadow"
                  >
                    <option value="name">Name</option>
                    <option value="lookup_id">Lookup ID</option>
                  </select>
                </div>
              )} */}

              {/* <div className="p-4">
                {isLoading && <p>Searching...</p>}
                {isError && (
                  <p>Error searching constituents: {error.message}</p>
                )}
                {filteredEvents?.length > 0 && (
                  <div className="flex-1">
                    <h3 className="text-lg font-bold mb-2 flex gap-2">
                      Results{" "}
                      <span className="text-gray-400">
                        {filteredEvents?.length} total
                      </span>
                    </h3>
                    <ul>
                      {filteredEvents?.map((event) => (
                        <li key={event.id} className="mb-2">
                          <EventCard
                            key={event.id}
                            event={event}
                            format={format}
                            handleEventClick={handleEventClick}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
