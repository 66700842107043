import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getEvents } from '../api'

export const useEvents = ({ searchParams }) => {
  const { data, isFetching, isLoading, isError, error, isSuccess, refetch } =
    useQuery({
      queryKey: ['events', searchParams],
      queryFn: () => getEvents(searchParams),
      enabled: !!searchParams,
    })

  if (data?.user) localStorage.setItem('token', data.user)

  const events = data?.value?.map((event) => ({
    id: event?.id,
    cms_id: event?.cms_id,
    title: event?.name,
    date: new Date(event.start_date),
    startDate: new Date(event?.start_date),
    endDate: new Date(event?.end_date),
    contact: event?.contact,
    location: event?.location,
    lookup_id: event?.lookup_id,
    room: event?.room,
    site: event?.site,
    allDay: true,
  }))

  useEffect(() => {
    refetch()
  }, [searchParams, refetch])

  return { events, isFetching, isLoading, isError, error, isSuccess, refetch }
}
