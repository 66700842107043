import React, { useState, useEffect } from 'react'
import { useConstituents, useSimplelist } from '../hooks'
import { SearchConstituents, Alert } from '../components'
import ConstituentCard from './ConstituentCard'
import { PlusIcon } from '@heroicons/react/24/outline'
import { postRegistrant } from '../api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { Guid } from 'js-guid'

export default function NewRegistrationModal({ closeModal, modalEvent }) {
  let data
  const queryClient = useQueryClient()
  const [alert, setAlert] = useState(null)
  const [searchParams, setSearchParams] = useState({})
  const [selectedConstituent, setSelectedConstituent] = useState(null)
  const [selectedValue, setSelectedValue] = useState('')
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const { constituents, isLoading, isError, error } = useConstituents({
    searchParams: searchParams,
  })

  const { simplelist, spIsLoading, spIsError, spError } = useSimplelist({
    selectedValue: selectedValue,
    searchParams: { parameters: 'event_id,' + modalEvent.id },
  })

  const mutation = useMutation({
    mutationFn: () => postRegistrant(data),
    onSuccess: () => {
      setAlert({ message: 'Registrant added!', type: 'success' })
      queryClient.invalidateQueries({ queryKey: ['event'] })
      closeModal()
    },
    onError: (error) => {
      setAlert({
        message: `An error occurred: ${error.message}`,
        type: 'error',
      })
    },
  })

  const handleSearch = (params) => {
    setSearchParams(params)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleMutate = () => {
    const guid = Guid.newGuid()
    const now = new Date()
    const formattedDate = formatDate(now)

    data = {
      event_id: modalEvent.id,
      constituent_id: selectedConstituent.id,
      date_purchased: formattedDate,
      waive_benefits: false,
      registrations: [
        {
          id: guid.toString(),
          event_price: selectedValue,
          quantity: 1,
          amount: 0,
          receipt_amount: 0,
          registration_count: 1,
        },
      ],
      registrant_mappings: [
        {
          id: guid.toString(),
          name: '',
          constituent_id: selectedConstituent.id,
        },
      ],
    }

    mutation.mutate()
  }

  return (
    <>
      <div className="fixed inset-0 flex items-end justify-center z-[1500]">
        <div
          className="fixed inset-0 bg-black opacity-0"
          onClick={closeModal}></div>
        <div className="bg-[#f3f4f2] p-8 rounded-t-xl shadow-lg w-screen h-[calc(100vh-50px)] z-[1000] overflow-y-auto">
          <h2 className="text-lg sm:text-2xl font-bold mb-4">
            New Registration
          </h2>
          <button
            className="bg-[#D6DBD4] px-4 py-2 rounded"
            onClick={closeModal}>
            Close
          </button>

          {mutation.isPending ? (
            <p className="mt-4">Adding registrant...</p>
          ) : (
            <>
              {spIsLoading && <p className="mt-4">Loading options...</p>}
              {spIsError && <p className="mt-4">Error {spError.message}</p>}
              {simplelist && (
                <SearchConstituents
                  setSelectedConstituent={setSelectedConstituent}
                  setIsSidebarOpen={setIsSidebarOpen}
                  isSidebarOpen={isSidebarOpen}
                  selectedConstituent={selectedConstituent}
                  handleSearch={handleSearch}
                  constituents={constituents}
                  isLoading={isLoading}
                  isError={isError}
                  error={error}
                />
              )}
              {selectedConstituent && (
                <>
                  <ConstituentCard
                    constituent={selectedConstituent}
                    selectedConstituent={selectedConstituent}
                    setSelectedConstituent={setSelectedConstituent}
                    setIsSidebarOpen={setIsSidebarOpen}
                  />

                  {simplelist?.results?.rows?.length > 0 ? (
                    <>
                      <p className="mt-4 mb-2 font-bold">
                        Choose a registration option
                      </p>
                      <select
                        value={selectedValue}
                        onChange={handleChange}
                        className="mb-4 p-2 rounded w-full shadow">
                        <option
                          value=""
                          disabled>
                          Select an option
                        </option>
                        {simplelist?.results?.rows.map((row) => (
                          <option
                            key={row.value}
                            value={row.value}>
                            {row.label}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <p className="mt-4">No registrations options available</p>
                  )}

                  {selectedValue ? (
                    <button
                      className="bg-[#B3A369] text-white px-4 py-2 mt-4 rounded flex items-center gap-2"
                      onClick={handleMutate}>
                      <PlusIcon className="w-4 h-4" /> Register Constituent
                    </button>
                  ) : (
                    ''
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  )
}

function formatDate(date) {
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  return `${year}-${month}-${day}` // Z to indicate UTC time
}
