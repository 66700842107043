import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Home } from './pages'
import { Login, Callback } from './auth'
import { ProtectedRoute } from './components'

const App = () => {
  const queryClient = new QueryClient()

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes>
            <Route
              path="/login"
              element={<Login />}
            />
            <Route
              path="/auth/callback"
              element={<Callback />}
            />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <Navigate
                  to="/404"
                  replace
                />
              }
            />
          </Routes>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  )
}

export default App
