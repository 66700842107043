import { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import ConstituentCard from './ConstituentCard'

export default function SearchConstituents({
  setSelectedConstituent,
  setIsSidebarOpen,
  isSidebarOpen,
  selectedConstituent,
  handleSearch,
  constituents,
  isLoading,
  isError,
  error,
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchType, setSearchType] = useState('name')
  const [searchParams, setSearchParams] = useState({
    constituent_quick_find: '',
    email_address: '',
    include_groups: false,
    include_individuals: true,
    include_organizations: false,
    exclude_households: true,
    check_alternate_lookup_ids: true,
  })

  const handleSearchChange = (e) => {
    const { name, value } = e.target
    setSearchParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }))
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSearch(searchParams)
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const filteredConstituents = constituents.filter((constituent) =>
    constituent[searchType].toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <>
      <div className="flex items-center  mb-4 sticky top-0 z-50 mt-4 lg:h-[87px]">
        <div className="min-h-10 gap-2 w-full max-w-7xl flex flex-col sm:flex-row">
          <button
            onClick={toggleSidebar}
            className="bg-[#B3A369] text-white px-4 py-2 rounded flex items-center gap-2">
            <MagnifyingGlassIcon className="w-4 h-4" /> Find constituent
          </button>
        </div>
      </div>

      {isSidebarOpen && (
        <div
          onClick={() => setIsSidebarOpen(false)}
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-end z-[1000]">
          <div
            className="bg-[#f3f4f2] w-full sm:w-2/4 lg:w-2/5 h-full flex flex-col"
            onClick={(e) => e.stopPropagation()}>
            <div className="flex-1 overflow-y-auto">
              <div className="p-4">
                <h2 className="text-2xl mb-4">Find Constituent</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block">Quick Find</label>
                    <input
                      name="constituent_quick_find"
                      placeholder="Name, lookup id, or alternate lookup id"
                      value={searchParams.constituent_quick_find}
                      onChange={handleSearchChange}
                      className="w-full p-2 rounded shadow"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block">Email</label>
                    <input
                      name="email_address"
                      placeholder="Email address"
                      value={searchParams.email_address}
                      onChange={handleSearchChange}
                      className="w-full p-2 rounded shadow"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block">Class of</label>
                    <input
                      name="classof"
                      placeholder="Primary class year"
                      value={searchParams.classof}
                      onChange={handleSearchChange}
                      className="w-full p-2 rounded shadow"
                      type="number"
                      maxLength={4}
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-[#B3A369] text-white p-2 rounded">
                    Find
                  </button>
                  <button
                    type="button"
                    onClick={toggleSidebar}
                    className="w-full bg-[#D6DBD4] p-2 rounded mt-2">
                    Close
                  </button>
                </form>
              </div>
              {/* {filteredConstituents.length > 0 && ( */}
              <div className="sticky top-0 z-10 bg-[#f3f4f2] p-4 shadow">
                <input
                  type="text"
                  placeholder="Search constituents"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  className="mb-4 p-2 rounded w-full shadow"
                />
                <select
                  value={searchType}
                  onChange={handleSearchTypeChange}
                  className="mb-4 p-2 rounded w-full shadow">
                  <option value="name">Name</option>
                  <option value="lookup_id">Lookup ID</option>
                </select>
              </div>
              {/* )} */}

              <div className="p-4">
                {isLoading && <p>Searching...</p>}
                {isError && (
                  <p>Error searching constituents: {error.message}</p>
                )}
                {filteredConstituents.length > 0 && (
                  <div className="flex-1">
                    <h3 className="text-lg font-bold mb-2 flex gap-2">
                      Results{' '}
                      <span className="text-gray-400">
                        {filteredConstituents.length} total
                      </span>
                    </h3>
                    <ul>
                      {filteredConstituents.map((constituent) => (
                        <li
                          key={constituent.id}
                          className="mb-2">
                          <ConstituentCard
                            setIsSidebarOpen={setIsSidebarOpen}
                            constituent={constituent}
                            selectedConstituent={selectedConstituent}
                            setSelectedConstituent={setSelectedConstituent}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
