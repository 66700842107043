import React, { useEffect } from 'react'

export default function Alert({ message, type, onClose }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 3000)

    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <div
      className={`fixed top-4 right-4 p-4 rounded shadow-lg transition-all z-[2000] ${
        type === 'success' ? 'bg-green-500' : 'bg-red-500'
      } text-white`}>
      {message}
    </div>
  )
}
