import { useEffect, useContext } from 'react'
import instance from '../api/axiosConfig'
import { LoadingIndicator } from '../components'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'

const Callback = () => {
  const { setIsAuthenticated, setUser } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchToken = async () => {
      const params = new URLSearchParams(window.location.search)
      const code = params.get('code')
      const codeVerifier = localStorage.getItem('codeVerifier')

      if (!code || !codeVerifier) {
        const token = localStorage.getItem('token')
        if (token) {
          const response = await instance.get('/auth/authenticated')
          if (response.data.authenticated) {
            setIsAuthenticated(true)
            setUser(response.data.user)
            navigate('/')
            return
          }
        }
        navigate('/login')
        return
      }

      try {
        const response = await instance.post("/auth/callback", {
          code,
          codeVerifier,
          protocol: window.location.protocol
        })

        const { token } = response.data

        if (token) {
          localStorage.setItem('token', token)
          localStorage.removeItem('codeVerifier')
          setIsAuthenticated(true)
          setUser(response.data.user)
          navigate('/')
        } else {
          setIsAuthenticated(false)
          navigate('/login')
        }
      } catch (error) {
        console.error(error)
        setIsAuthenticated(false)
        navigate('/login')
      }
    }

    fetchToken()
  }, [setIsAuthenticated, setUser, navigate])

  return <LoadingIndicator />
}

export default Callback
