import { getUniqueArray, getQueryString } from '../utils'
import instance from './axiosConfig'

export const getAuthStatus = async () => {
  const response = await instance.get('/auth/authenticated')
  return response.data
}

export const getCallback = async () => {
  const params = new URLSearchParams(window.location.search)
  const code = params.get('code')
  const codeVerifier = sessionStorage.getItem('codeVerifier')

  const response = await instance.post('/auth/callback', {
    code,
    codeVerifier,
  })

  return response.data
}

export const getConstituents = async (searchParams) => {
  const queryString = getQueryString(searchParams)
  const response = await instance.get('/api/constituents', {
    params: Object.fromEntries(queryString),
  })
  return getUniqueArray(response.data.value) || []
}

export const getSimplelist = async (searchParams) => {
  const queryString = getQueryString(searchParams)
  const response = await instance.get('/api/simplelist', {
    params: Object.fromEntries(queryString),
  })
  return response.data
}

export const getEvents = async (searchParams) => {
  const queryString = getQueryString(searchParams)
  const response = await instance.get('/api/events', {
    params: Object.fromEntries(queryString),
  })
  return response.data
}

export const getEvent = async (id) => {
  const response = await instance.get('/api/event', {
    params: { id },
  })
  return response.data
}

export const patchRegistrant = async (id, data) => {
  const response = await instance.patch(`/api/registrant`, data, {
    params: { id },
  })
  return response.data
}

export const postRegistrant = async (data) => {
  const response = await instance.post(`/api/registrant`, data)
  return response.data
}
