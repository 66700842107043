import { useState, useEffect } from 'react'
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  isWithinInterval,
} from 'date-fns'
import { EventCard, EventModal } from '../components'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'

function Calendar({ events }) {
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDayEvents, setSelectedDayEvents] = useState(events)
  const [modalEvent, setModalEvent] = useState(null)
  const [isClosing, setIsClosing] = useState(false)

  useEffect(() => {
    setSelectedDayEvents(events)
  }, [events])

  useEffect(() => {
    if (modalEvent) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [modalEvent])

  useEffect(() => {
    const handleScroll = () => {
      const eventTextElements = document.querySelectorAll('.event-text')
      const overlayPosition = 200 // Position from the top where overlay starts

      eventTextElements.forEach((el) => {
        const rect = el.getBoundingClientRect()
        if (rect.top <= overlayPosition) {
          const percentage = Math.min(1, (overlayPosition - rect.top) / 1)
          el.style.color = `rgba(255, 255, 255, ${percentage})`
        } else {
          el.style.color = '' // Reset to default color
        }
      })
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handlePrevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1))
  }

  const handleNextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1))
  }

  const handleDayClick = (day) => {
    if (selectedDay && isSameDay(day, selectedDay)) {
      setSelectedDay(null)
      setSelectedDayEvents(events)
    } else {
      setSelectedDay(day)
      const dayEvents = events.filter((event) =>
        isWithinInterval(day, { start: event.startDate, end: event.endDate })
      )
      setSelectedDayEvents(dayEvents)
    }
  }

  const handleEventClick = (event) => {
    setModalEvent(event)
    setIsClosing(false)
  }

  const closeModal = () => {
    setIsClosing(true)
    setTimeout(() => {
      setModalEvent(null)
      setIsClosing(false)
    }, 300) // Match the animation duration
  }

  const startMonth = startOfMonth(currentMonth)
  const endMonth = endOfMonth(currentMonth)
  const startDate = startOfWeek(startMonth)
  const endDate = endOfWeek(endMonth)
  const days = eachDayOfInterval({ start: startDate, end: endDate })

  return (
    <div className="relative flex lg:h-[710px]">
      <div className="w-full lg:w-1/4  lg:pt-4 overflow-y-scroll no-scrollbar h-full">
        <div className="flex items-center mb-4 gap-2">
          <h3 className="text-2xl font-bold h-[40px] flex items-center event-text text-white lg:text-gray-800 gap-2">
            Events{' '}
            <span className="text-[#B3A369] text-sm flex items-center">
              {selectedDayEvents.length} total
            </span>
          </h3>
        </div>
        {selectedDayEvents?.length > 0 ? (
          <div className="pt-[22px] lg:border-r lg:pr-4">
            <ul className="flex flex-col gap-1">
              {selectedDayEvents.map((event) => (
                <EventCard
                  key={event.id}
                  event={event}
                  format={format}
                  handleEventClick={handleEventClick}
                />
              ))}
            </ul>
          </div>
        ) : (
          <p className="text-gray-800">No events selected</p>
        )}
      </div>
      <div className="w-3/4 px-4 pt-4 hidden lg:visible lg:flex flex-col ">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handlePrevMonth}
            className="px-4 py-2 rounded">
            <ChevronDoubleLeftIcon className="h-6 w-6 text-[#B3A369]" />
          </button>
          <h2 className="text-2xl font-bold event-text text-white lg:text-gray-800">
            {format(currentMonth, 'MMMM yyyy')}
          </h2>
          <button
            onClick={handleNextMonth}
            className="px-4 py-2 rounded">
            <ChevronDoubleRightIcon className="h-6 w-6 text-[#B3A369]" />
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1">
          <div className="text-gray-800 event-text text-sm text-center">
            Sunday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Monday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Tuesday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Wednesday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Thursday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Friday
          </div>
          <div className="text-gray-800 event-text text-sm text-center">
            Saturday
          </div>
        </div>
        <div className="calendar grid grid-cols-7 gap-1">
          {days?.map((day, index) => {
            const dayEvents = events?.filter((event) =>
              isWithinInterval(day, {
                start: event.startDate,
                end: event.endDate,
              })
            )
            return (
              <div
                key={index}
                className={`p-2 rounded shadow ${
                  isSameMonth(day, currentMonth)
                    ? 'bg-white'
                    : 'bg-[#54585A]/10'
                } ${
                  isSameDay(day, selectedDay) ? 'border-2 border-[#B3A369]' : ''
                }`}
                style={{ height: '103px', cursor: 'pointer' }} // Set a fixed height for each tile
                onClick={() => handleDayClick(day)}>
                <div
                  className={`text-right ${
                    isSameDay(day, new Date()) ? 'font-bold text-[#B3A369]' : ''
                  }`}>
                  {format(day, 'd')}
                </div>
                {dayEvents?.length > 0 && (
                  <div className="mt-1 p-1 bg-[#003057] text-white text-sm rounded cursor-pointer">
                    {dayEvents.length} event{dayEvents.length > 1 ? 's' : ''}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      {modalEvent && (
        <EventModal
          closeModal={closeModal}
          isClosing={isClosing}
          modalEvent={modalEvent}
          format={format}
        />
      )}
    </div>
  )
}

export default Calendar
