import { useQuery } from '@tanstack/react-query'
import { getConstituents } from '../api'
import { useState, useEffect } from 'react'

// Utility function to check if an object is empty
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const useConstituents = ({ searchParams }) => {
  const [shouldFetch, setShouldFetch] = useState(false)

  const { data, isFetching, isLoading, isError, error, refetch } = useQuery({
    queryKey: ['constituents', searchParams],
    queryFn: () => getConstituents(searchParams),
    enabled: shouldFetch, // Only enable the query when shouldFetch is true
  })

  if (data?.user) localStorage.setItem('token', data.user)

  const constituents = data ?? []

  useEffect(() => {
    // Check if searchParams is not empty before setting shouldFetch
    if (!isEmptyObject(searchParams)) {
      setShouldFetch(true)
    } else {
      setShouldFetch(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (shouldFetch) {
      refetch() // Refetch data if shouldFetch is true
    }
  }, [shouldFetch, refetch])

  return { constituents, isFetching, isLoading, isError, error, refetch }
}
