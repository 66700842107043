import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { getSimplelist } from '../api'

export const useSimplelist = ({ searchParams, selectedValue }) => {
  const { data, isSuccess, isFetching, isLoading, isError, error, refetch } =
    useQuery({
      queryKey: ['simplelist', searchParams],
      queryFn: () => getSimplelist(searchParams),
      enabled: false,
    })

  if (data?.user) localStorage.setItem('token', data.user)

  const simplelist = data ?? null
  const spIsLoading = isLoading
  const spIsFetching = isFetching
  const spIsError = isError
  const spError = error
  const spSuccess = isSuccess

  useEffect(() => {
    refetch()
  }, [selectedValue, refetch])

  return {
    simplelist,
    spIsFetching,
    spIsLoading,
    spIsError,
    spSuccess,
    spError,
  }
}
