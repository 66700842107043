import instance from '../api/axiosConfig'
import { AuthContext } from '../context/AuthContext'
import { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const Login = () => {
  const { isAuthenticated, setIsAuthenticated, setUser } =
    useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    if (token) {
      localStorage.setItem('token', token)
      setIsAuthenticated(true)
      // Optionally, you can set the user information here if available
    }
  }, [location.search, setIsAuthenticated])

  const handleBBLogin = async () => {
    try {
      setIsLoading(true)
      const response = await instance.post('/auth/login/bb', {
        protocol: window.location.protocol,
      })
      const { authorizationUri, codeVerifier } = response.data
      localStorage.setItem('codeVerifier', codeVerifier)
      window.location.href = authorizationUri
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCasLogin = async () => {
    try {
      setIsLoading(true)
      const response = await instance.get('/gtf/login/cas')
      const { url } = response.data
      window.location.href = url
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to="/"
        replace
      />
    )
  }

  return (
    <div>
      <div className="flex w-screen h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h1 className="mx-auto h-10 w-auto text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
            GTF Events
          </h1>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <button
                onClick={handleCasLogin}
                className="flex w-full gap-2 items-center justify-center rounded bg-[#EAAA00] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#FFCC00] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2875a3]">
                Sign in with GT{' '}
                {isLoading && (
                  <div className="w-5 h-5 border-4 border-[#2961FF] border-t-transparent rounded-full animate-spin"></div>
                )}
              </button>
              {/* <button
                onClick={handleBBLogin}
                className="mt-4 flex w-full justify-center items-center gap-2 rounded bg-[#2961FF] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#2974ff] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#2875a3]"
              >
                Sign in with Blackbaud{" "}
                {isLoading && (
                  <div className="w-5 h-5 border-4 border-[#EAAA00] border-t-transparent rounded-full animate-spin"></div>
                )}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
