import { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import instance from '../api/axiosConfig'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem('token')

      if (token) {
        try {
          const response = await instance.get('/auth/authenticated')
          if (response.data.authenticated) {
            setIsAuthenticated(true)
            setUser(response.data.user)
            localStorage.setItem('token', response.data.user)
          } else {
            localStorage.removeItem('token')
            setIsAuthenticated(false)
            setUser(null)
          }
        } catch (error) {
          console.error('Token validation error:', error)
          localStorage.removeItem('token')
          setIsAuthenticated(false)
          setUser(null)
        }
      } else {
        setIsAuthenticated(false)
        setUser(null)
      }

      setIsLoading(false)
    }

    checkAuthStatus()
  }, [])

  const logout = () => {
    localStorage.clear()
    setIsAuthenticated(false)
    setUser(null)
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        setIsAuthenticated,
        user,
        setUser,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
